<template lang="pug">
div
  b-modal#modal-prevent-closing(ref="modal" title="Upload File" @show="resetModal" @hidden="resetModal" @ok="handleOk")
    b-overlay(:show="isSaving" no-wrap="")
    form(ref="form" @submit.stop.prevent="handleSubmit")
      b-row.my-1
        b-col
          b-form-group#fieldset-1(:label="`${$t('gis.layer_name')} (TH)`" label-for="input-1")
            b-form-input#input-1(v-model="data.name_th" trim="" placeholder="untitle" :state="!(data.name_th == '')")
      b-row.my-1
        b-col
          b-form-group#fieldset-1(:label="`${$t('gis.layer_name')} (EN)`" label-for="input-1")
            b-form-input#input-1(v-model="data.name_en" trim="" placeholder="untitle" :state="!(data.name_en == '')")
      b-row
        b-col
          b-form-group#fieldset-1(:label="`${$t('gis.layer_desc')} (TH)`" label-for="textarea")
            b-form-textarea#textarea(v-model="data.description_th" rows="3" max-rows="6")
      b-row
        b-col
          b-form-group#fieldset-1(:label="`${$t('gis.layer_desc')} (EN)`" label-for="textarea")
            b-form-textarea#textarea(v-model="data.description_en" rows="3" max-rows="6")
      b-form-group#fieldset-1.pt-3(label-for="input-file")
        b-form-file#input-file(accept=".KMZ" v-model="file" drop-placeholder="Drop file here...")
  // end::Body
</template>

<script>
import * as Services from '../services.js';

export default {
  name: 'upload-comp',
  data() {
    return {
      mode: null,
      data: {
        id: 0,
        gis_id: 0,
        name_th: null,
        description_th: null,
        name_en: null,
        description_en: null,
      },
      file: null,
      isShow: false,
      isSaving: false,
    };
  },
  mounted() {},
  methods: {
    showModal(options) {
      this.data.gis_id = options.gis_id;
      this.$bvModal.show('modal-prevent-closing');
    },
    save() {
      this.isSaving = true;
      let formData = new FormData();
      formData.append('file', this.file);
      formData.append('gis_id', this.data.gis_id);
      formData.append('name_th', this.data.name_th);
      formData.append('description_th', this.data.description_th);
      formData.append('name_en', this.data.name_en);
      formData.append('description_en', this.data.description_en);
      this.$store
        .dispatch(Services.ADD_LAYER, formData)
        // go to which page after successfully login
        .then(res => {
          this.isSaving = false;
          if (res.status) {
            this.$bvToast.toast('Layer added.', {
              title: 'Successfully',
              variant: 'success',
              solid: true,
            });
          } else {
            this.$bvToast.toast('There is something wrong.', {
              title: 'Failed',
              variant: 'danger',
              noAutoHide: true,
              appendToast: true,
              solid: true,
            });
          }

          this.$parent.getDataList();
        })
        .catch(error => {
          console.log(error);
          this.isSaving = false;
        });
    },

    resetModal() {
      this.data.name_th = null;
      this.data.description_th = null;
      this.data.name_en = null;
      this.data.description_en = null;
    },
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      //Save Data
      this.save();
      // Hide the modal manually
      this.$nextTick(() => {
        this.$bvModal.hide('modal-prevent-closing');
      });
    },
  },
};
</script>

<style></style>
