<template lang="pug">
div
  b-modal#modal-data-edit(ref="modal" title="Data" @show="resetModal" @hidden="resetModal" @ok="handleOk")
    b-overlay(:show="isSaving" no-wrap="")
    form(ref="form" @submit.stop.prevent="handleSubmit")
      b-row.my-1
        b-col
          b-form-group#fieldset-1(description="" :label="`${$t('gis.layer_name')} (TH)`" label-for="input-1")
            b-form-input#input-1(v-model="data.name_th" trim="" placeholder="untitle" :state="!(data.name_th == '')")
      b-row.my-1
        b-col
          b-form-group#fieldset-1(description="" :label="`${$t('gis.layer_name')} (EN)`" label-for="input-1")
            b-form-input#input-1(v-model="data.name_en" trim="" placeholder="untitle" :state="!(data.name_en == '')")
      b-row
        b-col
          b-form-group#fieldset-1(description="" :label="`${$t('gis.layer_desc')} (TH)`" label-for="textarea")
            b-form-textarea#textarea(v-model="data.description_th" rows="3" max-rows="6")
      b-row
        b-col
          b-form-group#fieldset-1(description="" :label="`${$t('gis.layer_desc')} (EN)`" label-for="textarea")
            b-form-textarea#textarea(v-model="data.description_en" rows="3" max-rows="6")
      b-row.my-1
        b-col
          b-form-group#fieldset-1(description="" :label="$t('gis.layer_file')" label-for="input-3")
            b-form-input#input-3(v-model="data.filepath_display" trim="" disabled="")
  // end::Body
</template>

<script>
import * as Services from '../services.js';

export default {
  name: 'data-edit-comp',
  data() {
    return {
      mode: null,
      data: {
        id: 0,
        gis_id: '',
        name_en: null,
        description_en: null,
        name_th: null,
        description_th: null,
      },
      params: null,
      stationCodeState: null,
      dateState: null,
      timeState: null,
      depthState: null,
      isShow: false,
      isSaving: false,
    };
  },
  mounted() {},
  methods: {
    showModal(options) {
      this.mode = options.mode;
      if (options.mode == 'edit') {
        // this.data = options.data;
        this.data.id = options.data.id;
        this.data.gis_id = options.data.gis_id;
        this.data.name_th = options.data.name_th;
        this.data.description_th = options.data.description_th;
        this.data.name_en = options.data.name_en;
        this.data.description_en = options.data.description_en;
        this.data.filepath_display = options.data.filepath.split('kmz/')[1];
      }

      this.$bvModal.show('modal-data-edit');
    },
    getData(id) {
      if (this.mode == 'edit') {
        this.$store
          .dispatch(Services.GET_LAYER_DATA, {id: id})
          // go to which page after successfully login
          .then(res => {
            this.data = res.data[0];
          })
          .catch(error => {
            console.log(error);
          });
      }
    },

    save() {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_LAYER_DATA, this.data)
        // go to which page after successfully login
        .then(res => {
          // this.data = res.data[0];
          this.isSaving = false;
          this.$bvToast.toast('Data Saved.', {
            title: 'Successfully',
            variant: 'success',
            solid: true,
          });
          this.$parent.getDataList();
          // Hide the modal manually
          this.$nextTick(() => {
            this.$bvModal.hide('modal-data-edit');
          });
        })
        .catch(error => {
          console.log(error);
          this.isSaving = false;
        });
    },
    checkFormValidity() {
      const valid = this.$refs.form.checkValidity();
      this.stationCodeState = valid;
      return valid;
    },
    resetModal() {},
    handleOk(bvModalEvent) {
      // Prevent modal from closing
      bvModalEvent.preventDefault();
      // Trigger submit handler
      this.handleSubmit();
    },
    handleSubmit() {
      // Exit when the form isn't valid
      if (!this.checkFormValidity()) {
        return;
      }
      //Save Data
      this.save();
    },
  },
};
</script>

<style></style>
