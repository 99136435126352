<template lang="pug">
div
  .row
    .col-xxl-8
      .card.card-custom.gutter-b.example.example-compact(v-if='isReady')
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('gis.batch_description') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group(description='' :label="`${$t('gis.batch_name')} (TH)`" label-for='input-1')
            b-form-input#input-1(v-model='data.name_th' trim='' placeholder='untitle' :state="!(data.name_th == '')")
          b-form-group(description='' :label="`${$t('gis.batch_name')} (EN)`" label-for='input-2')
            b-form-input#input-2(v-model='data.name_en' trim='' placeholder='untitle' :state="!(data.name_en == '')")
          b-row
            b-col
              b-form-group(description='' :label="$t('ctd_data.station_lat')" label-for='input-1')
                b-form-input#input-1(v-model='data.lat' trim='' placeholder='Lat' :state="!(data.lat == '')")
            b-col
              b-form-group(description='' :label="$t('ctd_data.station_lon')" label-for='input-1')
                b-form-input#input-1(v-model='data.lon' trim='' placeholder='Lon' :state="!(data.lon == '')")
          b-form-group(description='' :label="`${$t('gis.batch_desc')} (TH)`" label-for='input-1')
            b-form-textarea#textarea(v-model='data.description_th' rows='5' max-rows='8')
          b-form-group(description='' :label="`${$t('gis.batch_desc')} (EN)`" label-for='input-1')
            b-form-textarea#textarea(v-model='data.description_en' rows='5' max-rows='8')
      b-overlay(:show='isSaving' no-wrap='')  
    .col-xxl-4
      .card.card-custom.gutter-b(v-if='isReady')
        .card-header.pb-0
          .card-title
            h3.card-label
              | {{ $t('gis.publish') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-button.mb-5(variant='primary' block='' @click='savePost') Save
        b-overlay(:show='isSaving' no-wrap='')
      
      .card.card-custom.gutter-b(v-if='isReady')
        .card-header.pb-0
          .card-title
            h3.card-label {{ $t('GENERAL.attachment') }}
          .card-toolbar
            .example-tools.justify-content-center
        .card-body.pt-3.pb-0
          b-form-group#fieldset-1(description='' label='' label-for='input-1')
            b-form-file(
              v-model='attachFiles'
              placeholder='กรุณาเลือกไฟล์'
              drop-placeholder='Drop file here...'
              :file-name-formatter='formatNames'
              multiple=''
              :show='!hasFiles'
            )
            b-button.btn-sm.mt-5(v-if='hasFiles' variant='danger' @click='clearAttachFiles' block='') Clear
            .clearfix.text-center.pt-3(v-if='hasFiles')
              span.center  OR 
              b-button.btn-sm.mt-3(variant='primary' @click='uploadAttachFiles' block='') Upload {{ attachFiles.length }} file(s)
          b-table(small='' responsive='sm' :items='data.attachment' :fields="['filename']" style='overflow: hidden')
            template(#cell()='data')
              | {{ data.value | str_limit(30) }}
              b-button.btn-sm.pt-0.pb-0.pr-2.pl-2.float-right(variant='danger' @click='deleteAttachment(data.item.id)') x
        b-overlay(:show='isSaving || isUploading' no-wrap='')

  .row
    .col-xxl-12
      .card.card-custom(v-if='isReady')
        .card-header
          .card-title
            h3.card-label {{ $t('gis.batch_data_list') }} - {{ gis ? gis.length : 0 }} record(s)
          .card-toolbar
            b-button-toolbar
              b-dropdown.mx-1(variant='primary' size='sm' @click='showAddModal()' right='' split='' text='Add Layer')
                b-dropdown-divider(v-if='gis != null')
                b-dropdown-item(variant='danger' @click='clearData()' v-if='gis != null') Clear
        .card-body.pt-3.pb-0
          b-table(striped='' hover='' :items='gis' :fields='fields')
            template(v-slot:cell(id)='data')  {{ ++data.index }} 
            template(v-slot:cell(action)='data')
              a.btn.btn-success.btn-sm.mr-1(:href='data.item.filepath' size='sm' variant='primary') Export
              b-button.mr-1(size='sm' variant='primary' @click='editRecord(data.item)') Edit
              b-button(size='sm' variant='danger' @click='deleteRecord(data.item.id)')  Delete 
          b-overlay(:show='isSaving' no-wrap='')  
  uploadComp(ref='uploadModal')
  dataEditComp(ref='dataEditComp')
</template>

<script>
import {SET_BREADCRUMB} from '@/core/services/store/breadcrumbs.module';
import * as Services from '../services.js';

import uploadComp from '../components/upload_comp.vue';
import dataEditComp from '../components/data_edit_comp';

export default {
  name: 'gis_batch_add',
  components: {
    uploadComp,
    dataEditComp,
  },
  data() {
    return {
      isReady: false,
      isSaving: false,
      isUploading: false,
      mode: null,
      data: {
        id: 0,
        type: 'gis',
        name_th: '',
        name_en: '',
        description_th: '',
        description_en: '',
        lat: '',
        lon: '',
        date: '',
        attachment: [],
      },
      gis: [],
      fields: [
        {
          key: 'id',
          label: '#',
          sortable: true,
        },
        {
          key: 'name',
          label: 'Layer',
          sortable: true,
        },
        {
          key: 'action',
          label: 'Actions',
        },
      ],
      // Attachment
      attachFiles: [],
    };
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{title: 'GIS Data', route: '/admin/gis'}, {title: 'Batch Data'}]);
    this.getData();
  },
  methods: {
    showAddModal() {
      if (this.data.id == 0) {
        this.$bvToast.toast('You have to save this data first.', {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        });
        return;
      }
      this.$refs.uploadModal.showModal({gis_id: this.data.id});
    },
    clearData() {
      if (this.gis == null || this.gis.length < 1) {
        this.$bvToast.toast('There is not data to delete.', {
          title: 'Error!',
          variant: 'danger',
          solid: true,
        });
        return;
      }
      this.deleteConfirm(() => {
        this.isSaving = true;
        this.$store
          .dispatch(Services.CLEAR_LAYER_DATA, {id: this.$route.params.id})
          // go to which page after successfully login
          .then(res => {
            this.getDataList();
            this.isSaving = false;
          })
          .catch(error => {
            // console.log(error);
            this.isSaving = false;
          });
      });
    },
    getData() {
      // console.log(this.$route.name);
      if (this.$route.name == 'gis.edit_batch') {
        this.mode = 'edit';
        this.$store
          .dispatch(Services.GET_BATCH_DATA, {id: this.$route.params.id})
          // go to which page after successfully login
          .then(res => {
            this.data = res.data[0];
            this.isReady = true;
          })
          .catch(error => {
            // console.log(error);
          });
        this.getDataList();
      } else {
        this.isReady = true;
        this.mode = 'add';
      }
    },
    getDataList() {
      this.$store
        .dispatch(Services.GET_LAYER_LIST, {gis_id: this.$route.params.id, order: 'id ASC'})
        .then(res => {
          this.gis = res.data;
          this.isReady = true;
        })
        .catch(error => {
          // console.log(error);
        });
    },
    editRecord(data) {
      this.$refs.dataEditComp.showModal({data: data, mode: 'edit'});
    },
    deleteRecord(id) {
      this.deleteConfirm(() => {
        this.$store
          .dispatch(Services.DELETE_LAYER_DATA, {id: id})
          .then(res => {
            if (res.status) {
              this.$bvToast.toast('Data has been deleted', {
                title: 'Successfully',
                variant: 'success',
                solid: true,
              });
              this.getDataList();
            } else {
              this.$bvToast.toast(res.message, {
                title: 'Error!',
                variant: 'danger',
                solid: true,
              });
            }
          })
          .catch(error => {});
      });
    },
    savePost(mode) {
      return new Promise((resolve, reject) => {
        if (this.data.name_th == '' || this.data.name_en == '' || this.data.lat == '' || this.data.lon == '') {
          this.$bvToast.toast('Please complete the form', {
            title: 'Error!',
            variant: 'danger',
            solid: true,
          });
        } else {
          this._savePost(mode);
          resolve();
        }
      });
    },
    _savePost(mode) {
      this.isSaving = true;
      this.$store
        .dispatch(Services.SAVE_BATCH_DATA, this.data)
        // go to which page after successfully login
        .then(res => {
          if (res.status) {
            this.data = res.data[0];

            //Upload Attachment
            this.uploadAttachFiles();
          }
          // console.log('Save data');
          this.isSaving = false;
          this.$bvToast.toast('Post Saved.', {
            title: 'Successfully',
            variant: 'success',
            solid: true,
          });
          if (this.$route.name == 'gis.add_batch') {
            this.$router.push({
              name: 'gis.edit_batch',
              params: {id: this.data.id},
            });
          }
        })
        .catch(error => {
          this.isSaving = false;
          // console.log(error);
        });
    },

    // Attachment
    uploadAttachFiles() {
      if (this.data.id) {
        //Upload Attachment
        if (this.attachFiles) {
          this.isUploading = true;
          const attachNum = this.attachFiles.length;
          let i = 0;
          this.attachFiles.forEach((item, index) => {
            let formData = new FormData();
            formData.append('file', item);
            formData.append('id', this.data.id);
            formData.append('table', 'gis');
            this.$store
              .dispatch('UPLOAD_ATTACH', formData)
              .then(res => {
                i++;
                if (res.status) {
                  this.$bvToast.toast('Upload [' + res.data.filename + '] successfully!', {
                    title: 'Successfully',
                    variant: 'success',
                    solid: true,
                  });
                } else {
                  this.$bvToast.toast(res.message, {
                    title: 'Upload Failed!',
                    variant: 'danger',
                    noAutoHide: true,
                    appendToast: true,
                    solid: true,
                  });
                }
                if (i === attachNum) {
                  this.getData();
                }
              })
              .catch(err => {});
          });
          this.attachFiles = [];
          setTimeout(() => {
            this.isUploading = false;
          }, 500);
        }
      } else {
        this.savePost();
      }
    },
    deleteAttachment(id) {
      this.deleteConfirm(() => {
        this.isUploading = true;
        this.$store
          .dispatch('DELETE_ATTACH', {id: id})
          .then(res => {
            this.getData();
            this.isUploading = false;
          })
          .catch(err => {
            this.isUploading = false;
          });
      });
    },
    formatNames(files) {
      return files.length === 1 ? files[0].name : `${files.length} files selected`;
    },
    clearAttachFiles() {
      this.attachFiles = [];
    },
  },
  computed: {
    hasFiles() {
      return !!this.attachFiles.length;
    },
  },
};
</script>

<style></style>
